.select-none-important * {
	user-select: none !important;
}
.highlighted {
	animation: highlight 4s
}


@keyframes highlight {
	0% {
		background: #42b983;
	}
	100% {
		background: none;
	}
}

.remove-mouse {
	cursor: none !important;
}

.remove-mouse button {
	cursor: none !important;
}
